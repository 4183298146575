import http from "@/utils/http";
import { getApiUrl } from "@/utils/url";

const actions = {
  list: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/order/list`, {
      data,
    });
  },
  detail: async (no: string) => {
    return await http.get(`${getApiUrl()}/api/order/${no}`);
  },
  pay: async (data: any) => {
    return await http.post(`${getApiUrl()}/api/order/pay`, {
      data,
    });
  },
  cancel: async (no: string) => {
    return await http.delete(`${getApiUrl()}/api/order/${no}`);
  },
};

export default {
  ...actions,
};
